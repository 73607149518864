import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * A pipe that safely converts HTML strings to renderable HTML while preserving Quill formatting
 */
@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
    
    constructor(private sanitizer: DomSanitizer) {}
    
    transform(value: string): SafeHtml {
        if (!value) {
            return '';
        }
        
        // Process the HTML to preserve Quill formatting
        let processedValue = this.preserveQuillFormatting(value);
        
        // Return sanitized HTML that's safe to render
        return this.sanitizer.bypassSecurityTrustHtml(processedValue);
    }
    
    /**
     * Preserves Quill editor formatting by adding appropriate classes and data attributes
     * that won't be stripped by Angular's sanitizer
     */
    private preserveQuillFormatting(html: string): string {
        // Handle font sizes
        if (html.includes('font-size')) {
            html = html.replace(/style="font-size:\s*0.75em;?"/gi, 'class="ql-size-small"');
            html = html.replace(/style="font-size:\s*1.5em;?"/gi, 'class="ql-size-large"');
            html = html.replace(/style="font-size:\s*2.5em;?"/gi, 'class="ql-size-huge"');
        }
        
        // Handle text colors (convert to data attributes and classes)
        if (html.includes('color:')) {
            // Extract the color value and store it in a data attribute
            html = html.replace(
                /style="(.*?)color:\s*(#[0-9a-f]{3,6}|rgb\([^)]+\));?(.*?)"/gi, 
                (match, before, color, after) => {
                    return `style="${before}${after}" data-color="${color}"`;
                }
            );
        }
        
        // Handle background colors
        if (html.includes('background-color:')) {
            html = html.replace(
                /style="(.*?)background-color:\s*(#[0-9a-f]{3,6}|rgb\([^)]+\));?(.*?)"/gi, 
                (match, before, color, after) => {
                    return `style="${before}${after}" data-background-color="${color}"`;
                }
            );
        }
        
        // Ensure lists have proper classes
        html = html.replace(/<ul>/gi, '<ul class="ql-list">');
        html = html.replace(/<ol>/gi, '<ol class="ql-list">');
        
        // Handle text alignment
        if (html.includes('text-align')) {
            // Center alignment
            html = html.replace(
                /style="(.*?)text-align:\s*center;?(.*?)"/gi, 
                'style="$1$2" class="ql-align-center" data-text-align="center"'
            );
            
            // Right alignment
            html = html.replace(
                /style="(.*?)text-align:\s*right;?(.*?)"/gi, 
                'style="$1$2" class="ql-align-right" data-text-align="right"'
            );
            
            // Justify alignment
            html = html.replace(
                /style="(.*?)text-align:\s*justify;?(.*?)"/gi, 
                'style="$1$2" class="ql-align-justify" data-text-align="justify"'
            );
            
            // Handle paragraph alignments
            html = html.replace(
                /<p style="text-align:\s*center;?(.*?)"/gi,
                '<p class="ql-align-center" style="$1" data-text-align="center"'
            );
            
            html = html.replace(
                /<p style="text-align:\s*right;?(.*?)"/gi,
                '<p class="ql-align-right" style="$1" data-text-align="right"'
            );
            
            html = html.replace(
                /<p style="text-align:\s*justify;?(.*?)"/gi,
                '<p class="ql-align-justify" style="$1" data-text-align="justify"'
            );
        }
        
        // Wrap content in a container div with our styling class
        return `<div class="quill-content-wrapper">${html}</div>`;
    }
} 
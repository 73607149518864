import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { environment } from "../../../environments/environment";
import {CommonUtilitiesService} from "../../services/common-utilities.service";

@Injectable()
export class AuthService
{
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private userService: UserService,
        private commonUtilitiesService: CommonUtilitiesService,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        throw new Error("not implemented");
    }

    get accessToken(): string
    {
        //return localStorage.getItem('accessToken') ?? '';
        throw new Error("not implemented");
    }

    getCurrentUser() {
        return this.userService.getLoggedInUser();
        //return JSON.parse(localStorage.getItem('currentUser'));
    }

    setCurrentUser(userInfo) {
        localStorage.setItem('currentUser', JSON.stringify(userInfo));
    }

    setRegistrationCompleted(regStatus){
        localStorage.setItem('registrationCompleted', JSON.stringify(regStatus));
    }

    setCurrentBrand(brand) {
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        currUser.currentBrand = brand;
        this.setCurrentUser(currUser);
    }

    getCurrentBrand() {
        return JSON.parse(localStorage.getItem('currentUser'))?.currentBrand;
    }

    getMe() {
        const currentUser = this.userService.getLoggedInUser();
        return Promise.resolve(currentUser);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    login(email: string, password: string, loginCode?: string) {
        const payload: any = { email, password };

        if (loginCode) {
            payload.loginCode = loginCode;
        }

        return this._httpClient.post<any>(`${environment.apiUrl}/user/login`, payload);
    }






    // check(): Observable<boolean>
    // {
    //     // Check if the user is logged in
    //     if ( this.getCurrentUser() )
    //     {
    //         return of(true);
    //     }
    //
    //     // Check the access token availability
    //     if ( !this.accessToken )
    //     {
    //         return of(false);
    //     }
    //
    //     // Check the access token expire date
    //     if ( AuthUtils.isTokenExpired(this.accessToken) )
    //     {
    //         return of(false);
    //     }
    //
    //     // If the access token exists and it didn't expire, sign in using it
    //     return this.signInUsingToken();
    // }
    //
    // signInUsingToken(): Observable<any>
    // {
    //     // Sign in using the token
    //     return this.http.post('api/auth/sign-in-with-token', {
    //         accessToken: this.accessToken
    //     }).pipe(
    //         catchError(() =>
    //
    //             // Return false
    //             of(false)
    //         ),
    //         switchMap((response: any) => {
    //
    //             // Replace the access token with the new one if it's available on
    //             // the response object.
    //             //
    //             // This is an added optional step for better security. Once you sign
    //             // in using the token, you should generate a new one on the server
    //             // side and attach it to the response object. Then the following
    //             // piece of code can replace the token with the refreshed one.
    //             if ( response.accessToken )
    //             {
    //                 this.accessToken = response.accessToken;
    //             }
    //
    //             // Set the authenticated flag to true
    //             this._authenticated = true;
    //
    //             // Store the user on the user service
    //             this._userService.user = response.user;
    //
    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }
}

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../core/auth/auth.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { DepartmentService } from '../../../../services/department.service';

export interface DialogData {
    _id: string;
    nameFirst: string;
    nameLast: string;
    email: string;
}

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss'],
    animations: fuseAnimations,
})
export class EditUserComponent implements OnInit, OnDestroy {
    @ViewChild('signUpNgForm') editProfileNgForm: NgForm;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: '',
    };
    showAlert: boolean = false;

    editProfileForm: UntypedFormGroup;
    currentUser;
    formType;
    isAdmin: boolean = false;

    roleDict: any;
    roleOptions: any;

    departmentDict: any;
    departmentOptions: any;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _userService: UserService,
        private _departmentService: DepartmentService,
        public dialogRef: MatDialogRef<EditUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    onClose(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.isAdmin = this.data.isAdmin;
        this.formType = this.data.formType;
        this.roleDict = this._userService.availableRoles();
        this.roleOptions = Object.keys(this.roleDict);

        this.editProfileForm = this._formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: this.formType === 'new' ? ['', Validators.required] : [''],
            phoneNumber: ['', this.phoneNumberValidator],
            department: [''],
            disabled: [false],
            role: [''],
        });

        this.getAvailableDepartments();

        if (this.formType === 'edit') {
            const user = this.data.user;
            this.editProfileForm.patchValue({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: this.formatPhoneNumberOnInit(user.phoneNumber),
                disabled: user.disabled,
                role: user.roles ? user.roles[0] : '',
                department: user.departments ? user.departments[0] : '',
            });
        }
    }

    ngOnDestroy(): void {
        console.log('closed');
    }

    /**
     * Formats the phone number while typing.
     */
    formatPhoneNumber(event: Event): void {
        const input = (event.target as HTMLInputElement);
        const value = input.value.replace(/\D/g, ''); // Remove non-numeric characters

        if (value.length <= 3) {
            input.value = value;
        } else if (value.length <= 6) {
            input.value = `${value.slice(0, 3)}-${value.slice(3)}`;
        } else {
            input.value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }

        this.editProfileForm.controls['phoneNumber'].setValue(input.value);
    }

    /**
     * Formats the phone number on initialization.
     */
    formatPhoneNumberOnInit(phoneNumber: string): string {
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/\D/g, '');
        if (digits.length === 10) {
            return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
        }
        return phoneNumber;
    }

    /**
     * Custom validator for phone number.
     */
    phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value?.replace(/\D/g, ''); // Remove dashes
        if (!value || value.length === 10) {
            return null;
        }
        return { invalidPhoneNumber: true };
    }

    /**
     * Fetch available departments.
     */
    getAvailableDepartments(): void {
        this._departmentService.getDepartments({}).subscribe((result) => {
            if (result && result.success && result.departments) {
                const availableDepartments = {};
                result.departments.map((d) => {
                    if (!d.disabled) {
                        availableDepartments[d._id] = d.name;
                    }
                });
                this.departmentDict = availableDepartments;
                this.departmentOptions = Object.keys(this.departmentDict);
            }
        });
    }

    /**
     * Update or create user with phone number formatted for backend.
     */
    updateUser(): void {
        try {
            const phoneNumber = this.editProfileForm.value.phoneNumber?.replace(/\D/g, ''); // Remove dashes
            if (phoneNumber && phoneNumber.length !== 10) {
                this.alert = {
                    type: 'error',
                    message: 'Phone number must be exactly 10 digits.',
                };
                this.showAlert = true;
                return;
            }

            const payload = {
                ...this.editProfileForm.value,
                phoneNumber,
                roles: [this.editProfileForm.value.role],
                departments: [this.editProfileForm.value.department],
            };

            if (this.formType === 'edit') {
                this._userService.updateUser(this.data.user._id, payload).subscribe((result) => {
                    if (result.success) {
                        this.onClose();
                    } else {
                        this.alert = {
                            type: 'error',
                            message: result.message || 'Something went wrong. Please try again.',
                        };
                        this.showAlert = true;
                    }
                });
            } else {
                this._userService.createUser(payload).subscribe((result) => {
                    if (result.success) {
                        this.onClose();
                    } else {
                        this.alert = {
                            type: 'error',
                            message: result.message || 'Something went wrong. Please try again.',
                        };
                        this.showAlert = true;
                    }
                });
            }
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    }

    /**
     * Delete user confirmation.
     */
    deleteUser(): void {
        if (confirm('Delete this user?')) {
            this._userService.deleteUser(this.data.user._id).subscribe((result) => {
                if (result.success) {
                    this.onClose();
                } else {
                    this.alert = {
                        type: 'error',
                        message: result.message || 'Something went wrong. Please try again.',
                    };
                    this.showAlert = true;
                }
            });
        }
    }
}

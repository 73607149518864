import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {of} from 'rxjs';
import jwtDecode from "jwt-decode";
import {environment} from "../../environments/environment";
import axios from "axios";
import { TokenService } from "./token.service";
import {Router} from "@angular/router";;
import {CommonUtilitiesService} from "./common-utilities.service";

interface TokenResult {
    token: string
}

export interface User {
    email: string,
    firstName: string,
    lastName: string,
    roles: string[],
    _id: string
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public constructor(
        private tokenService: TokenService,
        private _httpClient: HttpClient,
        private _commonUtilitiesService: CommonUtilitiesService,
        private _router: Router,
    ) {

    }
    // constant
    availableRoles(){
        return {
            tech: 'Technician',
            tech2: 'Technician 2',
            'fin-tech': 'Financial Technician',
            manager: 'Manager',
            admin: 'Admin',
        }
    }

    // Auth
    login(email: string, password: string) {
        return this._httpClient.post<any>(`${environment.apiUrl}/user/login`, {email, password});
    }

    register(params: object){
        return this._httpClient.post<any>(`${environment.apiUrl}/user/register`, params);
    }

    forgotPassword(params: object){
        return this._httpClient.post<any>(`${environment.apiUrl}/user/forgot-password`, params);
    }

    public isLoggedIn(): Boolean {
        return (this.getLoggedInUser()) !== null;
    }

    public loggedInRedirect =  async () => {
        const currentUser = this.getLoggedInUser();
        if(currentUser && currentUser.roles){
            if (currentUser.roles.includes('Client')){
                await this._router.navigate(['client/work-order']);
            } else {
                await this._router.navigate(['malco/work-order']);
            }
            window.location.reload();
        }
    }

    public getLoggedInUser(): User | null {
        const token =  this.tokenService.getTokenSync();
        if (!token || token === null) return null;
        try {
            const decoded = jwtDecode(token) as any;
            if (Date.now() >= decoded.exp * 1000) {
                return null;
            }
            const user = {
                firstName: decoded.firstName,
                lastName: decoded.lastName,
                email: decoded.email,
                roles: decoded.roles,
                _id: decoded.userId,
                departments: decoded.departments,
            }
            return user;
        } catch (e: any) {
            console.error("cannot decode jwt");
            console.error(e);
            return null;
        }
    }

    isAdmin(user: User){
        return user.roles.includes('admin');
    }

    public async logOut(): Promise<boolean> {
        this.tokenService.removeToken();
        return;
    }

    // Other
    getUser(id: any) {
        if (!id) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/user/${id}`
        );
    }

    getUserStatus(id: any) {
        if (!id) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.get<any>(
            `${environment.apiUrl}/user/status/${id}`
        );
    }

    getUsers(params: { [key: string]: any }) {
        // Create HttpParams from the params object
        let httpParams = new HttpParams();

        // Append each key-value pair from params to httpParams
        for (const key of Object.keys(params)) {
            if (Array.isArray(params[key])) {
                params[key].forEach((value: string) => {
                    httpParams = httpParams.append(key, value);
                });
            } else {
                httpParams = httpParams.append(key, params[key]);
            }
        }

        return this._httpClient.get<any>(`${environment.apiUrl}/user`, { params: httpParams });
    }

    getTechnicianDict(){
        return this._httpClient.get<any>(
            `${environment.apiUrl}/user/technicians`
        );
    }

    getDepartmentUsers(departmentId: string) {
        return this._httpClient.get<any>(
            `${environment.apiUrl}/user/department/${departmentId}`
        );
    }

    createUser(params: object) {
        // params['apiKey'] = environment.kyipApiKey;
        return this._httpClient.post<any>(`${environment.apiUrl}/user/`, params);
    }

    updateUser(userId: string, params: object) {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/user/${userId}`, params);
    }

    updateUserGeo(userId: string, params: object) {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.put<any>(`${environment.apiUrl}/user/geo/${userId}`, params);
    }

    deleteUser(userId: string) {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.delete<any>(`${environment.apiUrl}/user/${userId}`);
    }

    patch(userId: string, params: object)
    {
        if (!userId) {
            return of({
                success: false,
                error: 'user id not provided to service function'
            });
        }
        return this._httpClient.patch<any>(`${environment.apiUrl}/user/${userId}`, params);
    }
}

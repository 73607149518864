import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApexOptions} from "ng-apexcharts";
import {Subject} from "rxjs";
import {UserService} from "../../../../services/user.service";
import {Router} from "@angular/router";
import moment from "moment/moment";
import {CreateUserComponent} from "../../../../layout/common/user/create-user/create-user.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../../../core/auth/auth.service";
import {EditUserComponent} from "../../../../layout/common/user/edit-user/edit-user.component";
import {DepartmentService} from "../../../../services/department.service";

@Component({
  selector: 'app-weekly-report',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {
    alphabeticalAscending: Boolean = true;
    currentUser;
    userFilterText = "";
    users;
    roleDict;
    departmentDict;

    // template variables below
    data: any;

    chartProjectEarningDistribution: ApexOptions;
    chartProjectTimeDistribution: ApexOptions;
    chartUserTimeContribution: ApexOptions;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public dialog: MatDialog,
        private _userService: UserService,
        private _departmentService: DepartmentService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.currentUser = this._userService.getLoggedInUser();
        this.roleDict = this._userService.availableRoles();
        this._departmentService.getDepartments({}).subscribe(result => {
            if (result && result.success && result.departments){
                const availableDepartments = {};
                result.departments.map(d => {
                    availableDepartments[d._id] = d.name;
                });
                this.departmentDict = availableDepartments;
            }
        })
        this.loadUsers();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    loadUsers = () => {
        try {
            this._userService.getUsers({}).subscribe(result => {
                if(result && result.success){
                    this.users = result.users;
                }
            })
        } catch (e) {
            console.log(e);
        }
    }

    roleTabSelect($event) {
        // do something
    }

    editUser(user?) {
        let formType = "new";
        if (user) {
            formType = "edit";
        }
        const dialogRef = this.dialog.open(EditUserComponent, {
            width: '100vw',
            height: '100vh',
            panelClass: 'full-screen-dialog',
            data: {
                formType,
                ...(user && {'user': user}),
                isAdmin: this._userService.isAdmin(this.currentUser),
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.loadUsers();
        });
    }

    filteredUsers(inactive?) {
        if (!this.users || this.users.length == 0) {
            return [];
        }
        if (this.alphabeticalAscending) {
            this.users.sort(function (a, b) {
                if (a.lastName < b.lastName) {
                    return -1
                }
                if (a.lastName > b.lastName) {
                    return 1;
                }
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            });
        } else {
            this.users.sort(function (a, b) {
                if (a.lastName < b.lastName) {
                    return 1;
                }
                if (a.lastName > b.lastName) {
                    return -1;
                }
                if (a.firstName < b.firstName) {
                    return 1;
                }
                if (a.firstName > b.firstName) {
                    return -1;
                }
                return 0;
            });
        }

        let users = this.users.map(user => {
            if (user.firstName.toLowerCase().includes(this.userFilterText.toLowerCase())) {
                return user;
            }
            if (user.lastName.toLowerCase().includes(this.userFilterText.toLowerCase())) {
                return user;
            }
            if (user.email.toLowerCase().includes(this.userFilterText.toLowerCase())) {
                return user;
            }
        })

        if(!inactive){
            users = users.filter(user => {
                if(user && !user.disabled){
                    return user;
                }
            })
        }
        return users;
    }
}

